type Props = {
  'data-test'?: string;
};

function CloseOutline(props: Props) {
  return (
    <svg
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3ZM18 30C11.4 30 6 24.6 6 18C6 11.4 11.4 6 18 6C24.6 6 30 11.4 30 18C30 24.6 24.6 30 18 30Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M23.7338 12.2753C23.3788 11.9204 22.8055 11.9204 22.4505 12.2753L18 16.7167L13.5495 12.2662C13.1945 11.9113 12.6212 11.9113 12.2662 12.2662C11.9113 12.6212 11.9113 13.1945 12.2662 13.5495L16.7167 18L12.2662 22.4505C11.9113 22.8055 11.9113 23.3788 12.2662 23.7338C12.6212 24.0887 13.1945 24.0887 13.5495 23.7338L18 19.2833L22.4505 23.7338C22.8055 24.0887 23.3788 24.0887 23.7338 23.7338C24.0887 23.3788 24.0887 22.8055 23.7338 22.4505L19.2833 18L23.7338 13.5495C24.0796 13.2036 24.0796 12.6212 23.7338 12.2753Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M11.8685 11.8685C12.4431 11.2938 13.3726 11.2938 13.9472 11.8685L18.0004 15.9216L22.0528 11.8776C22.6274 11.3034 23.5571 11.3031 24.1315 11.8776C24.6971 12.4431 24.6971 13.3817 24.1315 13.9472L20.0788 18L24.1315 22.0528C24.7062 22.6274 24.7062 23.5569 24.1315 24.1315C23.5569 24.7062 22.6274 24.7062 22.0528 24.1315L18 20.0788L13.9472 24.1315C13.3726 24.7062 12.4431 24.7062 11.8685 24.1315C11.2938 23.5569 11.2938 22.6274 11.8685 22.0528L15.9212 18L11.8685 13.9472C11.2938 13.3726 11.2938 12.4431 11.8685 11.8685ZM13.1517 12.664C13.0165 12.5287 12.7992 12.5287 12.664 12.664C12.5287 12.7992 12.5287 13.0165 12.664 13.1517L17.5122 18L12.664 22.8483C12.5287 22.9835 12.5287 23.2008 12.664 23.336C12.7992 23.4713 13.0165 23.4713 13.1517 23.336L18 18.4878L22.8483 23.336C22.9835 23.4713 23.2008 23.4713 23.336 23.336C23.4713 23.2008 23.4713 22.9835 23.336 22.8483L18.4878 18L23.336 13.1517C23.4622 13.0256 23.4622 12.7992 23.336 12.6731C23.2008 12.5378 22.9835 12.5378 22.8483 12.6731L17.9996 17.5118L13.1517 12.664Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default CloseOutline;

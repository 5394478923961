import optimisticIdiotLookup from 'utils/hacks/optimisticIdiotLookup';
import { buildUrl } from 'utils/mediaServerImageMaker/mediaServerImageUrl';
import { CONTEXTS } from 'modules/Logger';
import {
  getAds,
  getCallLetters,
  getFormat,
  getMarkets,
  getSeedId,
} from 'state/Live/selectors';
import {
  getAmpUrl,
  getCountryCode,
  getMediaServerUrl,
  getSiteUrl,
  getSupportedCountries,
  getWebGraphQlUrl,
} from 'state/Config/selectors';
import { getLiveStationById, profileGraphQl } from 'state/Live/services';
import { getLocale } from 'state/i18n/selectors';
import {
  getSimilarLiveStations,
  receiveStations,
  setRELiveProfile,
} from 'state/Live/actions';
import { getSlugId } from 'state/Routing/selectors';
import { getUserProfile } from 'state/User/selectors';
import { LiveProfileResponse, Show } from 'state/Live/types';
import { PAGE_TYPE } from 'constants/pageType';
import { parseProfileGraphQlData } from 'state/Live/helpers';
import { requestRelatedPlaylists } from 'state/Playlist/actions';
import { requestRelatedPodcasts } from 'state/Podcast/actions';
import { setHeroPremiumBackground } from 'state/Hero/actions';
import { State } from 'state/buildInitialState';
import { STATION_TYPE } from 'constants/stationTypes';
import type { GetAsyncData } from 'state/types';

export const getAsyncData: GetAsyncData = () => {
  return async function thunk(dispatch, getState, { logger, transport }) {
    const state = getState();
    const userProfile = getUserProfile(state);
    const ampUrl = getAmpUrl(state);
    const countryCode = getCountryCode(state);
    const supportedCountries = getSupportedCountries(state);
    const siteUrl = getSiteUrl(state);
    const id = getSlugId(state);

    if (Number.isNaN(parseInt(id, 10))) {
      const errObj = new Error(`invalid id: ${id}`);
      logger.error(
        [CONTEXTS.ROUTER, CONTEXTS.LIVE],
        errObj.message,
        {},
        errObj,
      );
      return { notFound: true };
    }

    let liveStation;

    try {
      liveStation = await transport(getLiveStationById({ ampUrl, id }));
    } catch (e: any) {
      const errObj =
        e instanceof Error ? e : (
          new Error(e?.statusText ?? 'error getting live station')
        );
      logger.error(
        [CONTEXTS.ROUTER, CONTEXTS.LIVE],
        errObj.message,
        {},
        errObj,
      );
      const { response = {} } = e;
      const { status } = response;

      if (status === 404) {
        if (!__CLIENT__) {
          const countryCodes = supportedCountries.filter(
            code => code !== countryCode,
          );
          liveStation = await optimisticIdiotLookup(
            id,
            countryCodes,
            getState(),
          );
        } else {
          return { notFound: true };
        }
      } else {
        throw errObj;
      }
    }

    let profileData = {} as LiveProfileResponse;
    const stationData = liveStation?.data?.hits?.[0] ?? {};
    try {
      const { callLetters } = stationData;
      ({ data: profileData } = await transport(
        profileGraphQl({
          locale: getLocale(state),
          slug: callLetters.toLowerCase(),
          timeZone: userProfile.timeZone ?? 'America/New_York',
          url: getWebGraphQlUrl(state),
        }),
      ));
    } catch (e: any) {
      const errObj = new Error(
        e?.statusText ?? 'error getting Live Profile data',
      );
      logger.error(
        [CONTEXTS.ROUTER, CONTEXTS.LIVE],
        errObj.message,
        {},
        errObj,
      );
    }

    const relatedPodcastIds =
      profileData?.data?.sites?.find?.config?.podcasts?.general
        ?.default_podcasts ?? [];

    const relatedPlaylistIds =
      profileData?.data?.sites?.find?.config?.playlists?.general
        ?.default_playlists ?? [];

    const { seedId, id: stationId, website: stationSite } = stationData;
    const {
      upcoming = [],
      current,
      hero,
      leads,
      timeline,
      social,
      timeUntilShiftChange,
      legalLinks,
    } = parseProfileGraphQlData(
      profileData,
      // relative links on siteManager need to resolve to the station's
      { siteUrl, stationSite },
    );

    const { image, background } = hero;
    const primaryBackground =
      image ?
        buildUrl(
          { mediaServerUrl: getMediaServerUrl(state), siteUrl },
          image,
        )(buildUrl as any)
      : '';

    await Promise.all([
      dispatch(requestRelatedPodcasts(relatedPodcastIds)),
      dispatch(requestRelatedPlaylists(relatedPlaylistIds)),
      dispatch(receiveStations([stationData])),
      dispatch(
        setRELiveProfile(seedId || stationId, {
          current: { ...current, timeUntilShiftChange } as Show,
          leads,
          relatedPodcastIds,
          relatedPlaylistIds,
          social,
          timeline,
          upcoming,
          legalLinks,
        }),
      ),
      dispatch(setHeroPremiumBackground(String(primaryBackground), background)),
      dispatch(getSimilarLiveStations(stationId)),
    ]);

    return undefined;
  };
};

export function pageInfo(state: State) {
  const pageId = getSeedId(state);
  const model = {
    format: getFormat(state),
    markets: getMarkets(state),
  };

  return {
    ads: getAds(state),
    callLetters: getCallLetters(state),
    model,
    pageId,
    pageType: PAGE_TYPE.LIVE,
    stationType: STATION_TYPE.LIVE,
    targeting: {
      name: 'live',
      modelId: String(pageId),
      format: model.format,
      pageformat: model.format,
      market: model.markets[0]?.name,
      pagemarket: model.markets[0]?.name,
    },
  };
}

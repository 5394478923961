import FullFooter from './FullFooter';
import MiniFooter from './MiniFooter';

type Props = {
  miniFooter?: boolean;
  adAboveNav?: boolean;
  newFooter?: boolean;
  piiDashboardLink?: string;
  piiRegulationEnabled?: boolean;
  showTakeover?: boolean;
};

const Footer = ({
  miniFooter = false,
  adAboveNav,
  newFooter,
  piiDashboardLink,
  piiRegulationEnabled,
  showTakeover,
}: Props) => {
  if (miniFooter) {
    return (
      <MiniFooter
        piiDashboardLink={piiDashboardLink}
        piiRegulationEnabled={piiRegulationEnabled}
      />
    );
  }
  return (
    <FullFooter
      adAboveNav={adAboveNav}
      newFooter={newFooter}
      piiDashboardLink={piiDashboardLink}
      piiRegulationEnabled={piiRegulationEnabled}
      showTakeover={showTakeover}
    />
  );
};

export default Footer;

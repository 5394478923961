export const PAGE_TYPE = {
  ARTIST: 'artist',
  ARTIST_SONG: 'artist:song',
  ARTIST_DIRECTORY: 'artist-directory',
  ARTIST_ALBUM: 'artist:album',
  FAVORITES: 'favorites',
  GENRE: 'genre',
  LIVE: 'live',
  CONTENT: 'content',
  PLAYLIST: 'playlist',
  PLAYLIST_DIRECTORY: 'playlist:directory',
  PLAYLIST_SUB_DIRECTORY: 'playlist-sub-directory',
  SHOW: 'show',
  SHOW_CATEGORY: 'show:category',
  EPISODE: 'episode',
  RECURLY_HISTORY: 'recurly history',
  UPDATE_PAYMENT: 'Update Payment Info',
  MY_SONGS: 'my:songs',
  PROMO: 'promo',
  UPGRADE_PAGE: 'upgrade page',
  YOUR_LIBRARY: 'Your Library',
  CURATED_PLAYLIST: 'curatedPlaylist',
};

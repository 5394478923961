import styled from '@emotion/styled';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
});

export default Container;

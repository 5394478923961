import styled from '@emotion/styled';

export const BannerContainer = styled('div')(() => ({
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  width: '100%',
  zIndex: 112,
}));

export default BannerContainer;

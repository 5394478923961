import { PAGE_TYPE } from 'constants/pageType';

export function pageInfo() {
  return {
    ogTitle: 'iHeart Plus & All Access - Upgrade to a Free Trial | iHeart',
    pageName: 'upgrade',
    pageType: PAGE_TYPE.UPGRADE_PAGE,
    targeting: {
      name: 'upgrade',
      modelId: 'upgrade',
    },
    title: 'iHeart Plus & All Access - Upgrade to a Free Trial',
  };
}

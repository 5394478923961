export const ANCHOR = {
  X: {
    CENTER: 'center',
    LEFT: 'left',
    RIGHT: 'right',
  },
  Y: {
    BOTTOM: 'bottom',
    TOP: 'top',
  },
};

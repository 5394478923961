import useTheme from 'contexts/Theme/useTheme';

function OverflowIcon() {
  const theme = useTheme();

  return (
    <svg
      aria-label="Overflow Icon"
      css={{ height: '3.5rem', width: '3.5rem' }}
      fill="none"
      height="35"
      role="img"
      viewBox="0 0 35 35"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74998 14.5833C7.14581 14.5833 5.83331 15.8958 5.83331 17.5C5.83331 19.1042 7.14581 20.4167 8.74998 20.4167C10.3541 20.4167 11.6666 19.1042 11.6666 17.5C11.6666 15.8958 10.3541 14.5833 8.74998 14.5833ZM26.25 14.5833C24.6458 14.5833 23.3333 15.8958 23.3333 17.5C23.3333 19.1042 24.6458 20.4167 26.25 20.4167C27.8541 20.4167 29.1666 19.1042 29.1666 17.5C29.1666 15.8958 27.8541 14.5833 26.25 14.5833ZM17.5 14.5833C15.8958 14.5833 14.5833 15.8958 14.5833 17.5C14.5833 19.1042 15.8958 20.4167 17.5 20.4167C19.1041 20.4167 20.4166 19.1042 20.4166 17.5C20.4166 15.8958 19.1041 14.5833 17.5 14.5833Z"
        fill={theme.colors.gray[600]}
      />
    </svg>
  );
}

export default OverflowIcon;

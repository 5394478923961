import usePlayerColor from 'contexts/PlayerColor/usePlayerColor';

function PreviousIcon() {
  const { playerColor } = usePlayerColor();
  return (
    <svg
      aria-label="Previous Icon"
      css={{ height: '3.5rem', width: '3.5rem' }}
      fill="none"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 6C7.55 6 8 6.45 8 7V17C8 17.55 7.55 18 7 18C6.45 18 6 17.55 6 17V7C6 6.45 6.45 6 7 6ZM10.66 12.82L16.43 16.89C17.09 17.36 18.01 16.88 18.01 16.07V7.93C18.01 7.12 17.1 6.65 16.43 7.11L10.66 11.18C10.09 11.58 10.09 12.42 10.66 12.82Z"
        fill={playerColor}
      />
    </svg>
  );
}

export default PreviousIcon;

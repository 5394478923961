import usePlayerColor from 'contexts/PlayerColor/usePlayerColor';

function Back15Icon() {
  const { playerColor } = usePlayerColor();
  return (
    <svg
      aria-label="Back15 Icon"
      css={{ height: '3.5rem', width: '3.5rem' }}
      fill="none"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.99 5.00003V2.21003C11.99 1.76003 11.4499 1.54003 11.1401 1.86003L7.35006 5.65003C7.14987 5.85003 7.14987 6.16003 7.35006 6.36003L11.1401 10.15C11.4499 10.46 11.99 10.24 11.99 9.80003V7.00003C15.7199 7.00003 18.6699 10.42 17.8501 14.29C17.3801 16.56 15.54 18.39 13.28 18.86C9.70993 19.61 6.53 17.16 6.05001 13.85C5.98996 13.37 5.57003 13 5.07003 13C4.46994 13 3.98996 13.53 4.07003 14.13C4.68991 18.52 8.87008 21.77 13.6001 20.85C16.7199 20.24 19.2299 17.73 19.8401 14.61C20.83 9.48003 16.9399 5.00003 11.99 5.00003Z"
        fill={playerColor}
      />
      <path
        d="M10.8901 16H10.04V12.74L9.03 13.05V12.36L10.8 11.73H10.8901V16Z"
        fill={playerColor}
      />
      <path
        d="M12.6501 11.74L12.4001 13.91L13.07 14.07C13.1001 14.04 13.1301 14 13.1699 13.98C13.1899 13.97 13.2099 13.9575 13.2299 13.945C13.25 13.9325 13.27 13.92 13.29 13.91C13.33 13.89 13.3901 13.87 13.4399 13.86C13.49 13.85 13.56 13.84 13.6401 13.84C13.75 13.84 13.8601 13.86 13.9399 13.89C14.02 13.92 14.0901 13.97 14.1501 14.04C14.2099 14.11 14.25 14.18 14.28 14.28C14.31 14.38 14.32 14.48 14.32 14.59C14.32 14.7 14.32 14.8 14.29 14.9C14.26 15 14.2199 15.08 14.1799 15.15C14.1401 15.22 14.07 15.28 14 15.32C13.9299 15.36 13.8401 15.38 13.7299 15.38C13.56 15.38 13.4199 15.32 13.31 15.23C13.1999 15.14 13.1401 15 13.1201 14.82H12.28C12.28 15.02 12.33 15.2 12.4099 15.35C12.49 15.5 12.5901 15.64 12.7299 15.74C12.8701 15.84 13.02 15.93 13.1899 15.98C13.3601 16.03 13.54 16.06 13.7199 16.06C13.9699 16.06 14.1799 16.01 14.3601 15.94C14.54 15.87 14.6899 15.76 14.81 15.63C14.9299 15.5 15.02 15.35 15.08 15.18C15.1401 15.01 15.1699 14.83 15.1699 14.64C15.1699 14.42 15.1301 14.22 15.08 14.04C15.03 13.86 14.9399 13.71 14.83 13.59C14.7199 13.47 14.58 13.38 14.4199 13.31C14.26 13.24 14.08 13.21 13.8701 13.21C13.8 13.21 13.7299 13.22 13.6699 13.23C13.6101 13.24 13.55 13.26 13.49 13.27C13.4299 13.28 13.3801 13.3 13.3401 13.32C13.3235 13.3284 13.3069 13.335 13.2907 13.3413C13.2685 13.3501 13.2475 13.3584 13.2299 13.37L13.3401 12.45H15.04V11.74H12.6501Z"
        fill={playerColor}
      />
    </svg>
  );
}

export default Back15Icon;

// values from css
export const BREAKPOINTS = {
  ZERO: 0,
  XSMALL: 320,
  SMALL: 370,
  MEDIUM: 640,
  LARGE: 768,
  XLARGE: 1024,
  XXLARGE: 1160,
  X3LARGE: 1280,
  X4LARGENEW: 1336,
  X4LARGE: 1440,
} as const;

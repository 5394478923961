import usePlayerColor from 'contexts/PlayerColor/usePlayerColor';
import { useSelector } from 'react-redux';
import type { State } from 'state/types';

type Props = {
  'data-test'?: string;
};

function ReplayIcon({ 'data-test': dataTest }: Props) {
  const { playerColor } = usePlayerColor();
  const lang = useSelector<State, string>(state => state.i18nState.lang);

  return (
    <svg
      aria-label="Replay Icon"
      css={{ height: '3.5rem', width: '3.5rem' }}
      data-test={dataTest}
      fill="none"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {lang.includes('en') ?
        <path
          clipRule="evenodd"
          d="M5.40901 7.63638H6.06062L6.06802 7.6364L6.06804 7.63638H9.15645C9.6019 7.63638 9.82498 7.09781 9.51 6.78283L8.33756 5.61039C9.41627 4.99074 10.6668 4.6364 12.0001 4.6364C16.0669 4.6364 19.3637 7.93321 19.3637 12C19.3637 16.0669 16.0669 19.3637 12.0001 19.3637C9.72635 19.3637 7.69334 18.3332 6.34259 16.7137C6.16338 16.4989 5.90286 16.3637 5.62308 16.3637C4.95173 16.3637 4.53999 17.0806 4.95845 17.6056C6.60743 19.6743 9.14892 21 12.0001 21C16.9706 21 21.0001 16.9706 21.0001 12C21.0001 7.02948 16.9706 3.00004 12.0001 3.00004C10.2127 3.00004 8.54704 3.52106 7.14667 4.4195L5.76256 3.03539C5.44758 2.72041 4.90901 2.94349 4.90901 3.38894V7.13638C4.90901 7.41252 5.13287 7.63638 5.40901 7.63638ZM3.08565 10.922H2.49252V11.9272H3.09244C3.43655 11.9272 3.63803 11.7415 3.63803 11.4268C3.63803 11.1167 3.42523 10.922 3.08565 10.922ZM3.05169 12.4524H2.49252V13.6364H1.80884V10.3696H3.18073C3.89837 10.3696 4.33982 10.7681 4.33982 11.4178C4.33982 11.8343 4.12249 12.1966 3.74669 12.346L4.4349 13.6364H3.66067L3.05169 12.4524ZM5.4799 13.0523H6.96045V13.6364H4.79621V10.3696H6.96045V10.9537H5.4799V11.7166H6.87669V12.2577H5.4799V13.0523ZM7.44854 10.3696V13.6364H8.13222V12.6222H8.77742C9.46563 12.6222 9.9433 12.1717 9.9433 11.4993C9.9433 10.8201 9.48374 10.3696 8.81138 10.3696H7.44854ZM8.13222 10.9311H8.63027C9.02418 10.9311 9.2483 11.1303 9.2483 11.5016C9.2483 11.8683 9.01965 12.0675 8.62801 12.0675H8.13222V10.9311ZM11.0245 13.041H12.4779V13.6364H10.3408V10.3696H11.0245V13.041ZM14.965 13.6364H15.7053L14.5711 10.3696H13.7674L12.6332 13.6364H13.3192L13.5704 12.8576H14.7182L14.965 13.6364ZM14.1273 11.0488H14.1681L14.5733 12.3301H13.7176L14.1273 11.0488ZM17.2438 12.4343V13.6364H16.5601V12.4343L15.4214 10.3696H16.1616L16.8815 11.737H16.9223L17.6399 10.3696H18.3802L17.2438 12.4343Z"
          fill={playerColor}
          fillRule="evenodd"
        />
      : <path
          d="M12.2565 6.01245V3.45901C12.2565 3.04716 11.7623 2.84582 11.4785 3.13869L8.00074 6.60733C7.81769 6.79037 7.81769 7.07409 8.00074 7.25713L11.4694 10.7258C11.7623 11.0095 12.2565 10.8081 12.2565 10.3963V7.84287C15.6702 7.84287 18.3701 10.9729 17.6196 14.5147C17.1894 16.5923 15.5055 18.2671 13.4371 18.6973C10.1698 19.3837 7.25942 17.1414 6.82011 14.1121C6.75605 13.6728 6.37166 13.3341 5.92321 13.3341C5.37408 13.3341 4.93478 13.8192 5.008 14.3683C5.57543 18.3861 9.40101 21.3605 13.73 20.5185C16.5854 19.9603 18.8826 17.6631 19.4409 14.8076C20.3469 10.1126 16.7776 6.01245 12.2565 6.01245Z"
          fill={playerColor}
        />
      }
    </svg>
  );
}

export default ReplayIcon;

import CaretLeft from 'styles/icons/CaretLeft';
import styled from '@emotion/styled';

const BackIcon = styled(CaretLeft)({
  left: '0',
  position: 'absolute',
  top: '0.8rem',
});

export default BackIcon;

import styled from '@emotion/styled';

const Handle = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.red[500],
  borderRadius: '0.2rem',
  height: '0.6rem',
  width: '0.2rem',
}));

export default Handle;

export enum PlaybackState {
  Buffering = 'BUFFERING',
  Idle = 'IDLE',
  Loading = 'LOADING',
  Paused = 'PAUSED',
  Playing = 'PLAYING',
}

export enum StationType {
  Album = 'album',
  Artist = 'artist',
  Episode = 'episode',
  Favorites = 'favorites',
  Live = 'live',
  MyMusic = 'mymusic',
  Playlist = 'collection',
  PlaylistRadio = 'playlistradio',
  Podcast = 'podcast',
  Track = 'track',
}

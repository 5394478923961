import PageBody from './PageBody';

export default PageBody;

export {
  forYouItemSelectedLocation,
  forYouPlayedFrom,
  ViewName,
} from './constants';
export type { MainStyles, Props } from './types';

import usePlayerColor from 'contexts/PlayerColor/usePlayerColor';

type Props = {
  filled?: boolean;
};

function UpIcon({ filled = false }: Props) {
  const { playerColor } = usePlayerColor();
  return (
    <svg
      aria-label={`Thumb Up Icon${filled ? ' Filled' : ''}`}
      css={{ height: '2.4rem', width: '2.4rem' }}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ?
        <path
          clipRule="evenodd"
          d="M14.8879 3.04997C14.2979 2.46997 13.3579 2.46997 12.7781 3.05996L7.23804 8.59997C6.86792 8.96997 6.65796 9.47996 6.65796 10.01V20C6.65796 21.1 7.55786 22 8.65796 22H17.658C18.458 22 19.178 21.52 19.498 20.79L22.7581 13.18C23.5979 11.2 22.1479 8.99997 19.998 8.99997H14.3479L15.2979 4.41997C15.3979 3.91997 15.248 3.40996 14.8879 3.04997Z"
          fill={playerColor}
          fillRule="evenodd"
        />
      : <path
          clipRule="evenodd"
          d="M14.8879 3.04997C14.2979 2.46997 13.3579 2.46997 12.7781 3.05996L7.23804 8.59997C6.86792 8.96997 6.65796 9.47996 6.65796 10.01L6.65796 20C6.65796 21.1 7.55786 22 8.65796 22L17.658 22C18.458 22 19.178 21.52 19.498 20.79L22.7581 13.18C23.5979 11.2 22.1479 8.99997 19.998 8.99997L14.3479 8.99997L15.2979 4.41997C15.3979 3.91997 15.248 3.40996 14.8879 3.04997ZM17.6655 19.9887L20.9168 12.399C20.9172 12.3982 20.9175 12.3973 20.9179 12.3965C21.1989 11.7292 20.707 11 19.998 11L11.8905 11L12.9959 5.67057L8.65812 10.0083C8.65802 10.0089 8.65796 10.0095 8.65796 10.01L8.65796 20L17.6556 20C17.6558 19.9999 17.6554 20.0001 17.6556 20C17.6589 19.9978 17.6628 19.994 17.6655 19.9887ZM8.65203 10.0144C8.6521 10.0143 8.65196 10.0145 8.65203 10.0144V10.0144Z"
          fill={playerColor}
          fillRule="evenodd"
        />
      }
      <path
        d="M4.65796 11.6162C4.65796 10.5116 3.76253 9.61621 2.65796 9.61621C1.55339 9.61621 0.657959 10.5116 0.657959 11.6162V19.6162C0.657959 20.7208 1.55339 21.6162 2.65796 21.6162C3.76253 21.6162 4.65796 20.7208 4.65796 19.6162V11.6162Z"
        fill={playerColor}
      />
    </svg>
  );
}

export default UpIcon;

export enum BlockType {
  Asset = 'asset',
  Catalog = 'catalog',
  E2E = 'e2e',
  Embed = 'embed',
  Gallery = 'gallery',
  Html = 'html',
  NotFound = 'notFoundButtons',
  Ohembed = 'ohembed',
}

export enum BlockKind {
  Station = 'station',
  TalkShow = 'talkShow',
  Artist = 'artist',
  Track = 'track',
  Curated = 'curated',
  Podcast = 'podcast',
}

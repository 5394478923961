import Copyright from './primitives/Copyright';
import FooterWrap from './primitives/MiniFooterWrap';

type Props = {
  help: string;
  piiDashboardLink?: string;
  piiRegulationEnabled?: boolean;
  privacy: string;
  terms: string;
};

const MiniFooter = ({
  help,
  piiDashboardLink,
  piiRegulationEnabled,
  privacy,
  terms,
}: Props) => (
  <FooterWrap>
    <Copyright
      helpLink={help}
      piiDashboardLink={piiDashboardLink}
      piiRegulationEnabled={piiRegulationEnabled}
      privacyLink={privacy}
      termsLink={terms}
    />
  </FooterWrap>
);

export default MiniFooter;

export const SITE_IDS = {
  1: 15287,
  2: 15298,
  3: 15289,
  4: 15300,
  5: 15285,
  6: 15286,
  7: 15299,
  8: 15291,
  9: 15288,
  10: 15297,
  11: 15302,
  12: 15292,
  13: 15290,
  14: 15294,
  15: 15293,
  16: 15284,
  17: 15301,
  18: 15305,
  19: 15296,
  77: 15295,
  93: 15307,
  94: 15306,
  96: 15308,
  97: 15310,
  98: 15303,
  100: 15304,
  101: 15309,
  104: 15550,
  106: 15547,
  108: 15548,
  1191: 15032,
} as const;

export function getSiteDataIdByGenreId(genreId: keyof typeof SITE_IDS) {
  return SITE_IDS[genreId];
}

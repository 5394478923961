import { PAGE_TYPE } from 'constants/pageType';

export function pageInfo() {
  return {
    ogTitle: '',
    pageName: 'recurly-history',
    pageType: PAGE_TYPE.RECURLY_HISTORY,
    title: 'Recurly History Page',
  };
}
